namespace $.$$ {
	
	$mol_style_define( $hyoo_crus_land_rights, {
		
		Gift_peer: {
			flex: {
				grow: 1,
			},
			padding: $mol_gap.text,
			gap: $mol_gap.text,
		},
		
		Gift_rank: {
			flex: {
				grow: 0,
			},
		},
		
	} )
	
}
